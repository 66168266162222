import * as React from 'react';
import './styles.scss';

interface Props {
  name: string;
  link: string;
  openNewTab?: boolean;
}

const Button: React.FunctionComponent<Props> = ({ name, link, openNewTab }) => {
  return (
    <div className="button">
      <a href={link} target={openNewTab ? '_blank' : undefined}>
        <div className="button__content">
          {name}
        </div>
      </a>
    </div>
  );
};

export default Button;

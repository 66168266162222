import { graphql, useStaticQuery } from 'gatsby';
import * as React from 'react';
import { ReactNode } from 'react';
import Helmet from 'react-helmet';
import Footer from '../footer/component';
import NavBar from '../nav-bar/component';
import './styles.scss';

interface Props {
  description?: string;
  tabTitle?: string;
  title?: string;
  children?: ReactNode;
  footnote?: string | JSX.Element;
}

interface LayoutData {
  site: {
    siteMetadata: {
      description: string;
      title: string;
      titleTemplate: string;
      tagline: string;
      menuLinks: MenuLink[]
      footer: string;
    },
  };
}

interface MenuLink {
  name: string;
  link: string;
}

const Layout: React.FunctionComponent<Props> = ({ tabTitle, description, children, footnote, title }) => {
  const data: LayoutData = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
          titleTemplate
          tagline
          description
          menuLinks {
            name
            link
          }
          footer
        }
      }
    }
  `);

  const customTabTitle = tabTitle ? tabTitle : data.site.siteMetadata.title;

  return (
    <div>
      <Helmet titleTemplate={data.site.siteMetadata.titleTemplate}>
        <html lang="en" />
        <title>{customTabTitle}</title>
        <meta name="description" content={data.site.siteMetadata.description} />
      </Helmet>
      <NavBar
        siteTitle={data.site.siteMetadata.tagline}
        menuLinks={data.site.siteMetadata.menuLinks}
      />
      <div className="layout">
        {getPageDetails(title, description)}
        <div>
          {children}
        </div>
        {getFootnote(footnote)}
        <Footer content={data.site.siteMetadata.footer}/>
      </div>
    </div>
  );
};

function getPageDetails(title: string | undefined, description: string | undefined) {
  if (title || description) {
    return (
      <div className="layout__details">
        {getTitle(title)}
        {getDescription(description)}
      </div>
    );
  }
}

function getTitle(title: string | undefined) {
  if (title) {
    return (
      <div className="layout__title">
        <h1>
          {title}
        </h1>
      </div>
    );
  }
}

function getDescription(description: string | undefined) {
  if (description) {
    return (
      <div className="layout__description">
        <p>
          {description}
        </p>
      </div>
    );
  }
}

function getFootnote(footnote: string | JSX.Element | undefined) {
  if (footnote) {
    return (
      <div className="layout__footnote">
        {footnote}
      </div>
    );
  }
}

export default Layout;

import * as React from 'react';
import './styles.scss';

interface Props {
  content: string;
}

const Footer: React.FunctionComponent<Props> = ({ content }) => {
  return (
    <div
      className="footer"
    >
      {content}
    </div>
  );
};

export default Footer;

import { Link } from 'gatsby';
import * as React from 'react';
import './styles.scss';

interface Props {
  siteTitle: string;
  className?: string;
  menuLinks: MenuLink[];
}

interface MenuLink {
  name: string;
  link: string;
}

const NavBar: React.FunctionComponent<Props> = ({ siteTitle, className, menuLinks }) => {
  const navBarClassName = className ? className + 'navBar' : 'navBar';
  return (
    <div className={navBarClassName}>
      <div className="navBar__site-title">
        {siteTitle}
      </div>
      <div className="navBar__navigation-link-container">
        {getNavigationLinks(menuLinks)}
      </div>
    </div>
  );
};

function getNavigationLinks(menuLinks: MenuLink[]): Array<JSX.Element | undefined> {
  return (
    menuLinks.map((link) => {
      if (link.name !== 'home') {
        return (
          <Link
            to={link.link}
            className="navBar__link navBar__link--navigation"
            activeClassName="navBar__link--current"
          >
            {link.name}
          </Link>
        );
      }
    })
  );
}

export default NavBar;
